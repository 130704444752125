<template>
  <page :title="i18n.download">
    <div class="download-content">
      <img class="img_logo" src="../../assets/imgs/logo.png" alt="" />
      <div class="tips">{{ i18n.download_tips }}</div>
      <div class="downloadbtn btn" @click="onDownload">
        <span>{{ i18n.download }}</span>
      </div>
    </div>
  </page>
</template>

<script>
import { mapState } from "vuex"
export default {
  watch: {},
  computed: {
    ...mapState(["system"]),
    i18n() {
      return this.$t("result")
    },
    i18nMsg() {
      return this.$t("msg")
    }
  },
  data() {
    return {
      downloadApp: false
    }
  },
  methods: {
    onDownload() {
      let dowmloadUrl = process.env.VUE_APP_GOOGLE_PLAY
      if (this.system === "ios") {
        dowmloadUrl = process.env.VUE_APP_APP_STORE
      }
      if (dowmloadUrl) {
        window.open(dowmloadUrl)
      } else {
        this.$toast(this.i18nMsg.appoffline)
      }
    }
  }
}
</script>

<style lang="less">
.download-content {
  padding-top: 40px;
  min-height: 100vh;
  background: #fff;

  .unlocked {
    max-width: 30%;
    display: block;
    margin: 0px auto;
    padding: 50px 0 0;
  }

  h2 {
    font-size: 52px;
    font-family: "Montserrat-SemiBold";
    font-weight: bold;
    text-align: center;
    margin: 0;
    padding: 40px 0;
  }

  .img_logo {
    max-width: 44%;
    display: block;
    margin: 40px auto 80px;
  }

  .tips {
    line-height: 1.8;
    font-size: 28px;
    color: #41200f;
    text-align: center;
    margin: 20px 60px 40px;
  }

  .downloadbtn {
    width: 80%;
    margin: 18vh auto 0;
    font-family: "DIN Condensed Bold";
    font-size: 46px;
    font-weight: bold;
    padding: 8px 0;
    border-radius: 20px;

    span {
      display: block;
    }
  }
}
</style>
